<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
                <div class="admin_main_block_left">
                    <div><router-link to="/Admin/kecscpj/form"><el-button type="primary" icon="el-icon-plus">添加</el-button></router-link></div>
                    <div><el-input v-model="where.F_VR_NIC" placeholder="请输入昵称"></el-input></div>
                    <div><el-button icon="el-icon-search" @click="get_adv_list()">条件筛选</el-button></div>
                </div>

                <div class="admin_main_block_right">
                    <div><el-button type="danger" icon="el-icon-delete" @click="del(select_id)">批量删除</el-button></div>
                </div>
            </div>
            <div class="admin_table_main">
                <el-table :data="list" @selection-change="handleSelectionChange" >
                    <el-table-column type="selection"></el-table-column>
                    <!-- <el-table-column prop="id" label="#" fixed="left" width="70px"></el-table-column> -->
                    <el-table-column prop="F_IN_ID" label="#"  width="70px"></el-table-column>
                    <!--<el-table-column prop="F_IN_YINGQ_ID" label="营期"></el-table-column>
                    <el-table-column prop="F_IN_YINGQQX_ID" label="营期期限"></el-table-column>-->
                    <el-table-column label="用户头像" width="100px">
                        <template slot-scope="scope">
                            <dl class="table_dl">
                                <dt><img :src="scope.row.F_VR_TOUX||require('@/assets/default_pic.png')" width="50px" height="50px"></dt>
                            </dl>
                        </template>
                    </el-table-column>
                    <el-table-column prop="F_VR_NIC" label="昵称" width="80px"></el-table-column>
                    <el-table-column prop="F_VR_OPENID_ID" label="openid" width="100px"></el-table-column>
                    <el-table-column prop="F_VR_JIANJ" label="课程" width="150px"></el-table-column>
                    <el-table-column prop="F_IN_PINGJXJ" label="评价星级" width="100px"></el-table-column>
                    <el-table-column prop="F_VR_PINGJNR" label="评价内容"></el-table-column>
                    <el-table-column prop="F_IN_CHUANGJSJ" label="创建时间" >
                        <template slot-scope="scope">
                            <div v-if="scope.row.F_IN_PINGJSJ<=0"> - </div>
                            <div v-else>{{scope.row.F_IN_PINGJSJ|formatDate}}</div>
                        </template>
                    </el-table-column>



                    <el-table-column label="操作" fixed="right" width="120px">
                        <template slot-scope="scope">
                            <el-button icon="el-icon-edit" @click="$router.push({name:'kecscpj_form',params:{id:scope.row.F_IN_ID}})">编辑</el-button>
                            <!-- <el-button type="danger" icon="el-icon-delete" @click="del(scope.row.id)">删除</el-button> -->
                        </template>
                    </el-table-column>

                </el-table>
                <div class="admin_table_main_pagination">
                    <el-pagination @current-change="current_change" background layout="prev, pager, next,jumper,total" :total="total_data" :page-size="page_size" :current-page="current_page"></el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
      return {
          list:[],
          total_data:0, // 总条数
          page_size:20,
          current_page:1,
          select_id:'',
          adv_position_id:0,
          where:{
              F_VR_JIANJ:'',
          },
      };
    },
    watch: {},
    computed: {},
    methods: {
        handleSelectionChange:function(e){
            let ids = [];
            e.forEach(v => {
                ids.push(v.F_IN_ID);
            });
            this.select_id = ids.join(',');
        },
        get_adv_list:function(){
            this.where.page = this.current_page;
            this.$get(this.$api.getKecscpjList,this.where).then(res=>{
                this.page_size = res.data.per_page;
                this.total_data = res.data.total;
                this.current_page = res.data.current_page;
                this.list = res.data.data;
            })

        },
        // 删除处理
        del:function(F_IN_ID){
            if(this.$isEmpty(F_IN_ID)){
                return this.$message.error('请先选择删除的对象');
            }
            this.$post(this.$api.delKecscpj,{F_IN_ID:F_IN_ID}).then(res=>{
                if(res.code == 200){
                    this.get_adv_list();
                    return this.$message.success("删除成功");
                }else{
                    return this.$message.error("删除失败");
                }
            });
        },
        current_change:function(e){
            this.current_page = e;
            this.get_adv_list();
        },
    },
    created() {
        this.get_adv_list();
    },
    mounted() {}
};
</script>
<style lang="scss" scoped>
</style>